export default function () {

	$('.js-age-checker:not(.js-age-checker-done)').each(function () {
		var wrapper = $(this);
		var min = new Date(wrapper.attr('data-age-min'));
		var max = new Date(wrapper.attr('data-age-max'));
		var day = wrapper.find('.js-age-checker-day');
		var month = wrapper.find('.js-age-checker-month');
		var year = wrapper.find('.js-age-checker-year');
		var error = wrapper.find('.js-age-checker-error');

		var _check = function () {
			if (day.val() == '' || month.val() == '' || year.val() == '') {
				error.hide();
				return;
			}
			var date = new Date(year.val(), parseInt(month.val(), 10) - 1, day.val());
			if (date >= min && date <= max) {
				error.hide();
			}
			else {
				error.show();
			}
		};

		// change
		day.add(month).add(year).off('change').on('change', function () {
			_check();
		});

		_check();

		// done
		wrapper.addClass('js-age-checker-done');
	});

}
